// store/tabletIdSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// 로컬 스토리지에서 값 가져오기
const getStorageValue = (key: string, defaultValue: string) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};
const initialState = {
  currentTabletID: getStorageValue('tabletId', '1'),
};

const tabletIdSlice = createSlice({
  name: 'tabletId',
  initialState,
  reducers: {
    setTabletId: (state, action: PayloadAction<string | undefined>) => {
      const newTabletId = action.payload;
      localStorage.setItem('tabletId', JSON.stringify(newTabletId));
      return { ...state, currentTabletID: newTabletId ?? '' };
    },
  },
});

export const { setTabletId } = tabletIdSlice.actions;
export default tabletIdSlice.reducer;
