import { SameRefundInfo } from '@/types/refund';
import { Button, Icon } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  supplyDate: string;
  data: SameRefundInfo[];
  onClick: () => void;
  onClose: () => void;
};

const SameRefundCheckModal = ({
  supplyDate,
  data,
  onClick,
  onClose,
}: Props) => {
  return (
    <BackDrop>
      <Wrapper>
        <Title>
          <Icon.WarningFilled fillColor='#777' size={28} />
          <span>동일한 금액의 환급 내역이 존재합니다.</span>
        </Title>
        <Contents>
          <Strong>{supplyDate}</Strong>
          에 처리된 동일한 환급 내역이 있습니다.
          <br />
          계속 진행하려면 [환급하기]를 눌러주세요.
        </Contents>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th style={{ width: 150 }}>등록시간</th>
                <th style={{ width: 80 }}>국적</th>
                <th style={{ width: 200 }}>고객명</th>
                <th>총 시술 금액</th>
              </tr>
            </thead>
            <tbody>
              {data.map((refund) => {
                return (
                  <tr>
                    <td>{refund.supplyDateTime}</td>
                    <td>{refund.nationality}</td>
                    <td>{refund.touristName}</td>
                    <td>{Number(refund.totalPrice).toLocaleString()} 원</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
        <ButtonWrapper>
          <Button type='button' onClick={onClose} theme='mono'>
            닫기
          </Button>
          <Button
            type='button'
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            환급하기
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </BackDrop>
  );
};
export default SameRefundCheckModal;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  border-radius: var(--Radius-16-Card, 16px);
  background: var(--Mono-00, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  padding: 32px;
  width: 640px;
`;

const Title = styled.h6`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Mono-100, #030303);
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.48px;
  margin-bottom: 12px;
`;

const Contents = styled.p`
  color: var(--Mono-65, #5f6165);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.32px;
`;

const Strong = styled.strong`
  color: #ed0828;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  padding: 12px;
  margin: 24px 0;
  background: var(--Mono-05, #f5f6f7);
`;

const Table = styled.table`
  width: 100%;
  border-radius: var(--Radius-4-Input, 4px);
  background: var(--Mono-05, #f5f6f7);
  thead {
    border-bottom: 1px solid #d9d9d9;
  }
  th {
    color: var(--KTP-Grayscale-Gray_05, #777);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.28px;
    text-align: left;
    padding-bottom: 8px;
  }
  td {
    color: var(--KTP-Grayscale-Gray_03, #333);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.28px;
    padding-top: 8px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
