import { Button, Icon } from '@successmode-ktp/kui';
import styled, { css } from 'styled-components';
import ICON_ALERT from '@/assets/common/icon_alert.png';
import { useEffect, useMemo, useState } from 'react';
import UpdateBannerModal from './UpdateBannerModal';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import { useMutation } from 'react-query';
import { readBanner } from '@/api/user';

type Props = {
  isInTemporaryModal?: boolean;
};

function UpdateBanner({ isInTemporaryModal = false }: Props) {
  const [isShowBanner, setIsShowBanner] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openedBannerId, setOpenedBannerId] = useState();
  const [hasNew, setHasNew] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [bannerList, setBannerList] = useState<any[]>([]);
  const { globalStatus } = useGetGlobalStatus();

  const showBanner = useMemo(() => {
    if (globalStatus?.bannerData) {
      return globalStatus.bannerData?.length > 0;
    }
  }, [globalStatus]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (globalStatus?.bannerData) {
      setBannerList(globalStatus.bannerData);

      const length = globalStatus.bannerData?.length || 0;

      if (length > 0) {
        const timeoutId = setInterval(() => {
          setCurrentBannerIndex((prev) => (prev + 1 === length ? 0 : prev + 1));
        }, 5000);

        if (isOpenModal) {
          clearTimeout(timeoutId);
        }

        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [globalStatus, isOpenModal]);

  const handleToggleShowBanner = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const currentState = localStorage.getItem('isShowBanner');
    if (currentState === 'false') {
      localStorage.setItem('isShowBanner', 'true');
    } else {
      localStorage.setItem('isShowBanner', 'false');
    }

    setIsShowBanner((prev) => !prev);
  };

  useEffect(() => {
    if (localStorage.getItem('isShowBanner') === 'false') {
      setIsShowBanner(false);
    }
  }, []);

  useEffect(() => {
    setHasNew(bannerList.findIndex((banner) => banner.isNew) !== -1);
  }, [bannerList]);

  const handleClickModal = () => {
    if (isShowBanner) {
      handleOpenModal();
      setOpenedBannerId(bannerList[currentBannerIndex].bannerId);
    }
  };

  const handleClickNewLabel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleOpenModal();
    setOpenedBannerId(bannerList[0].bannerId);
    setHasNew(false);
  };

  const { mutate: mutateReadBanner } = useMutation(readBanner);
  const handleOpenModal = () => {
    if (!!bannerList[currentBannerIndex].bannerId) {
      mutateReadBanner(
        { bannerId: bannerList[currentBannerIndex].bannerId },
        {
          onSuccess: () => {
            setIsOpenModal(true);
          },
        },
      );
    } else {
      setIsOpenModal(true);
    }
  };

  return (
    <>
      {showBanner && (
        <Wrapper
          isInTemporaryModal={isInTemporaryModal}
          onClick={handleClickModal}
          isShowBanner={isShowBanner}
        >
          {isShowBanner && (
            <>
              <Title key={currentBannerIndex}>
                {hasNew ? (
                  <NewLabel>NEW</NewLabel>
                ) : (
                  <img src={ICON_ALERT} alt='' width={24} />
                )}
                <span>{bannerList[currentBannerIndex]?.title}</span>
              </Title>
              <Button
                type='button'
                onClick={handleOpenModal}
                size='sm'
                style={{ width: '105px', fontSize: '14px' }}
                enabled={isInTemporaryModal}
              >
                자세히 보기
              </Button>
            </>
          )}
          <ShowButton
            onClick={handleToggleShowBanner}
            isShowBanner={isShowBanner}
          >
            <Icon.ChevronUp size={34} fillColor='#111111' />
          </ShowButton>
          {hasNew && !isShowBanner && (
            <NewLabelPopover onClick={handleClickNewLabel}>
              <img src={ICON_ALERT} alt='' width={16} />
              NEW
            </NewLabelPopover>
          )}
        </Wrapper>
      )}
      {isOpenModal && openedBannerId && (
        <UpdateBannerModal
          isOpen={isOpenModal}
          onClose={handleCloseModal}
          showBannerId={openedBannerId}
        />
      )}
    </>
  );
}

const Wrapper = styled.aside<{
  isShowBanner: boolean;
  isInTemporaryModal?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9f0fe;
  padding: 16px 48px;
  width: 100%;

  ${({ isInTemporaryModal }) =>
    isInTemporaryModal &&
    css`
      border-radius: 12px;
      padding: 16px 24px;
      background-color: #f4f8ff;
      margin-top: 8px;
    `}
`;

const Title = styled.strong`
  @keyframes flip {
    0% {
      transform: rotateX(0);
      color: var(--Blue-100);
    }
    50% {
      transform: rotateX(-180deg);
      color: var(--Blue-150);
    }
    100% {
      transform: rotateX(-360deg);
      color: var(--Blue-100);
    }
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: var(--Blue-100);
  font-size: 18px;
  font-weight: 700;

  span {
    animation: flip 1s;
  }
`;

const ShowButton = styled.button<{ isShowBanner: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: -17px;
  transform: translateX(-50%);
  width: 34px;
  height: 34px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 10;

  svg {
    transition: all 200ms;
  }

  ${({ isShowBanner }) =>
    !isShowBanner &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const NewLabel = styled.strong`
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #246cf6;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
`;

const NewLabelPopover = styled(NewLabel)`
  @keyframes blink-effect {
    50% {
      opacity: 0;
    }
  }

  position: absolute;
  bottom: -64px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  animation: blink-effect 1s step-end infinite;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 42px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #246cf6 transparent;
  }
`;

export default UpdateBanner;
