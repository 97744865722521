import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import useCreateRefundCheck from '@/hooks/useCreateRefundCheck';
import { getRefundAmount } from '@/util/getRefundAmount';
import { isCustom } from '@/constants/common';
import useRefundCheckDataSubscribe from '@/hooks/useRefundCheckDataSubscribe';
import { REFUNDSTATUS } from '@/constants/refund.const';
import useDeleteRefundCheck from '@/hooks/useDeleteRefundCheck';
import { setPrivacyAgreementDate } from '@/store/modules/refund';

function RefundCheckButton({
  totalPayPrice,
  isCustomKor,
  isDisabledRefundButton,
  onClickRefundBtn,
}: {
  totalPayPrice: number;
  isCustomKor: boolean;
  isDisabledRefundButton: boolean;
  onClickRefundBtn: () => void;
}) {
  const { currentTabletID } = useAppSelector((state) => state.tabletId);
  const { isDirectPayment, isDirectPassport } = useAppSelector(
    (state) => state.refund,
  );
  const { isAutoPayment, hospitalId } = useAppSelector((state) => state.user);
  const { mutateAsync: createRefundCheckMutate } = useCreateRefundCheck(
    hospitalId.toString(),
    currentTabletID,
  );
  const { refundInfo } = useRefundCheckDataSubscribe(
    hospitalId.toString(),
    currentTabletID,
  );
  const { mutateAsync: deleteRefundCheckMutate } = useDeleteRefundCheck(
    hospitalId.toString(),
    currentTabletID,
  );

  const isSent = refundInfo && refundInfo?.status === REFUNDSTATUS.SENT;
  const isValidRefundCheckButton =
    totalPayPrice !== 0 && !isDisabledRefundButton;
  const dispatch = useAppDispatch();

  const getFinalPrice = (totalPayPrice: number) => {
    if (isCustom(hospitalId) && !isCustomKor) {
      return totalPayPrice - getRefundAmount(totalPayPrice);
    }

    if (isAutoPayment && !isDirectPayment && !isDirectPassport) {
      return totalPayPrice - getRefundAmount(totalPayPrice);
    }

    return totalPayPrice;
  };

  const createRefundCheck = async () => {
    try {
      await createRefundCheckMutate({
        totalAmount: totalPayPrice,
        refundAmount: getRefundAmount(totalPayPrice),
        finalAmount: getFinalPrice(totalPayPrice),
      });
      console.log('Refund Check created successfully');
    } catch (error) {
      console.error('Error creating refund check:', error);
    }
  };

  useEffect(() => {
    if (isSent) {
      dispatch(setPrivacyAgreementDate(refundInfo?.privacyAgreementDate));
      onClickRefundBtn();
    }
  }, [isSent]);

  useEffect(() => {
    if (refundInfo?.id) {
      deleteRefundCheckMutate({ refundId: refundInfo.id });
    }
  }, [totalPayPrice, isDisabledRefundButton]);

  return (
    <ButtonWrapper>
      <Button
        isValidate={!isSent && isValidRefundCheckButton}
        disabled={isSent || !isValidRefundCheckButton}
        onClick={() => {
          createRefundCheck();
          onClickRefundBtn();
        }}
      >
        확인
      </Button>
    </ButtonWrapper>
  );
}
export default RefundCheckButton;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const Button = styled.button<{
  isValidate?: boolean;
  temporaryStorage?: boolean;
}>`
  width: ${(props) => (props.temporaryStorage ? '200px' : '240px')};
  height: 52px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.temporaryStorage ? props.theme.mono[80] : '#ffffff'};
  background-color: ${(props) =>
    props.temporaryStorage
      ? props.theme.mono[10]
      : props.isValidate
      ? props.theme.blue[100]
      : props.theme.blue[20]};
  :hover {
    opacity: 0.7;
  }
`;
