import { Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  code: 'T0002' | '4007'; // T0002: 특수직업군, 4007: 입국 6개월 경과
  onClose: () => void;
};

function NonRefundableModal({ code, onClose }: Props) {
  const MESSAGE =
    code === 'T0002'
      ? {
          kor: '외교관, 공무원, 군인과 같은 특수 성격을 띄는 여권입니다',
          eng: 'This passport belongs to a special category, such as for diplomats, government officials, or military personnel',
        }
      : {
          kor: '해당 여권은 한국 입국 후 6개월을 경과하였습니다',
          eng: 'This passport has been in Korea for more than 6 months after entry',
        };

  return (
    <Modal isOpen={true} hasBackdrop>
      <Header>
        <Icon.WarningFilled fillColor='#FF2D55' size={28} />
        <Title>
          <TitleKor>택스리펀 적용 대상이 아닙니다.</TitleKor>
          <TitleEng>Not eligible for tax refund.</TitleEng>
        </Title>
      </Header>

      <Body>
        <GrayBox>
          <RedText>비대상 사유</RedText>
          <RedText>A verification error may also occur in this case</RedText>
          <KorText>{MESSAGE.kor}</KorText>
          <EngText>{MESSAGE.eng}</EngText>
        </GrayBox>
      </Body>

      <Modal.Footer>
        <Modal.Button onClick={onClose}>확인</Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 32px;
`;

const Title = styled.h4`
  color: #030303;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleKor = styled.strong`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
`;

const TitleEng = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const Body = styled.main`
  width: 652px;
`;

const GrayBox = styled.section`
  padding: 20px 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  background-color: #f5f6f7;
  border-radius: 4px;
`;

const RedText = styled.p`
  color: #ff2d55;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
`;

const KorText = styled.p`
  color: #3a3b3e;
  margin-bottom: 2px;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const EngText = styled.p`
  color: #5f6165;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export default NonRefundableModal;
