import styled from 'styled-components';
import FlexWrap from '@/components/common/FlexWrap';
import ExcelTable from '@/components/refund/ExcelTable';
import Terms from '../refundDetail/Terms';
// import { useAppSelector } from '@/hooks/useReduxHooks';
// import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

interface IProps {
  isDetailModal?: boolean;
  touristIndex: number;
  refundIndex: number;
  touristName?: string;
  supplyDate?: string;
  privacyAgreedTime?: string;
}

function PrintElement({
  refundIndex,
  touristIndex,
  isDetailModal,
  ...termsProps
}: IProps) {
  // 일단 차감, 전액, 키오스크 할 것 없이 다 보여준다.
  // const { isKioskStore } = useAppSelector((state) => state.user);
  // const { globalStatus } = useGetGlobalStatus();
  // const isOriginalPayment = globalStatus?.paymentType === 'ORIGINAL';

  // 키오스크 병원은 키오스크에서 동의 프로세스 진행
  // 차감결제 병원은 환급내역에서 동의서 생성
  // 전액결제 병원은 동의서 필요없음.
  return (
    <Container dir='column' isDetailModal={isDetailModal}>
      <ExcelTable touristIndex={touristIndex} refundIndex={refundIndex} />
      <Terms {...termsProps} />
    </Container>
  );
}

const Container = styled(FlexWrap)<{ isDetailModal?: boolean }>`
  width: ${(props) => (props.isDetailModal ? '100%' : '1200px')};
`;

export default PrintElement;
