import styled from 'styled-components';
import HomeContent from '@/components/home/HomeContent';
import Footer from '@/components/common/Footer';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import CashbillUpdateModal from '@/components/home/CashbillUpdateModal';
import { useEffect, useState } from 'react';
import UpdateBanner from '@/components/common/UpdateBanner';

function Home() {
  const { isExpiryWarning, globalStatus } = useGetGlobalStatus();
  const [isOpenModal, setIsOpenModal] = useState(
    globalStatus?.isCashBillModalVisible || false,
  );

  useEffect(() => {
    setIsOpenModal(globalStatus?.isCashBillModalVisible || false);
  }, [globalStatus]);

  return (
    <Wrapper>
      <UpdateBanner />
      <Container>
        {isExpiryWarning && (
          <WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
        )}
        <HomeContent />
        <Footer />
      </Container>
      {globalStatus?.paymentType === 'ORIGINAL' &&
        !globalStatus?.isBeforeNovember && (
          <CashbillUpdateModal
            isOpen={globalStatus?.paymentType === 'ORIGINAL' && isOpenModal}
            onClose={() => setIsOpenModal(false)}
          />
        )}
    </Wrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.pointColors.white};
  width: 100%;
  height: 100vh;
  flex: 1;
  overflow: auto;
  position: relative;
`;

const Wrapper = styled.div`
  min-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

export default Home;
