import axios, { AxiosRequestConfig } from 'axios';

const SERVER_URL = 'https://api-logncrash.nhncloudservice.com';

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: SERVER_URL,
  responseType: 'json',
};

const axiosInstance = axios.create(axiosRequestConfiguration);

const currentUrl: string = window.location.href;
const isProduction =
  process.env.NODE_ENV === 'production' && !currentUrl.includes('test');

export const postLogNCrash = async (
  body: string,
  traceId?: string,
  category?: string,
) => {
  const newPayload = {
    projectName: process.env.REACT_APP_NHN_KEY,
    projectVersion: '6.1.3',
    logVersion: 'v2',
    body,
    logSource: 'http',
    logType: category || 'medical-user-scan',
    category: category || 'medical-user-scan',
    logLevel: isProduction ? 'INFO' : 'DEBUG',
    trace_id: traceId,
  };
  const res = await axiosInstance.post('/v2/log', newPayload);
  return res.data;
};
