import { useState } from 'react';
import styled, { css } from 'styled-components';

import Delete from '@/assets/kiosk/delete_disabled.png';
import Upload from '@/assets/kiosk/upload.png';
import Calendar from '@/assets/kiosk/calendar.png';
import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';
import VideoListItem from '@/components/kiosk/VideoListItem';
import VideoTableHeader from '@/components/kiosk/VideoTableHeader';
import DeleteAllModal from '@/components/kiosk/DeleteAllModal';
import UploadModal from '@/components/kiosk/UploadModal';
import useKioskVideoList from '@/hooks/useKioskVideoList';
import UpdateBanner from '@/components/common/UpdateBanner';

import { ACCEPTED_FILE_TYPES } from '@/constants/kiosk.const';

function KioskSetting() {
  const { data, isLoading } = useKioskVideoList();
  const [file, setFile] = useState();
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [uploadType, setUploadType] = useState<
    null | 'RESERVATION' | 'DEFAULT'
  >(null);

  const onOpenUploadModal = () => {
    setIsOpenUploadModal(true);
  };
  const onCloseUploadModal = () => {
    setIsOpenUploadModal(false);
    setFile(undefined);
    //@ts-ignore
    document.getElementById('reservation_upload').value = '';
    //@ts-ignore
    document.getElementById('default_upload').value = '';
  };
  const onToggleDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };

  const onChange = (e: any) => {
    if (data?.length === 10) {
      alert('최대 10개까지만 동영상 업로드가 가능합니다.');
      return;
    }
    setFile(e.target.files[0]);
    const newUploadType =
      e.target.name === 'reservation_upload' ? 'RESERVATION' : 'DEFAULT';
    setUploadType(newUploadType);
    onOpenUploadModal();
  };

  return (
    <Container>
      <UpdateBanner />
      <Title>키오스크 설정</Title>
      <Content>
        {isLoading && <LoadingView height='50vh' />}
        {(data || []).length === 0 ? (
          <NoVideo>
            재생할 동영상이 없습니다. 동영상 업로드를 진행해주세요.
          </NoVideo>
        ) : (
          <>
            <FlexWrap justifyContent='space-between'>
              <GuideText>동영상은 최근 10개까지 저장됩니다.</GuideText>
              <TextBtn onClick={onToggleDeleteModal}>
                <Icon src={Delete} /> 동영상 전체 삭제
              </TextBtn>
            </FlexWrap>
            <Table>
              <VideoTableHeader />
              {data?.map((v, i) => (
                <VideoListItem key={i} data={v} idx={i} length={data.length} />
              ))}
            </Table>
          </>
        )}
      </Content>
      <BtnContainer>
        <Button>
          <BtnIcon src={Calendar} />
          예약 업로드
          <UploadInput
            type='file'
            id='reservation_upload'
            name='reservation_upload'
            accept={ACCEPTED_FILE_TYPES}
            onChange={onChange}
          />
        </Button>
        <Button colored>
          <BtnIcon src={Upload} />
          동영상 업로드
          <UploadInput
            type='file'
            id='default_upload'
            name='default_upload'
            accept={ACCEPTED_FILE_TYPES}
            onChange={onChange}
          />
        </Button>
      </BtnContainer>
      {isOpenUploadModal && file && (
        <UploadModal
          file={file}
          closeModal={onCloseUploadModal}
          isReservation={uploadType === 'RESERVATION'}
        />
      )}
      {isOpenDeleteModal && <DeleteAllModal closeModal={onToggleDeleteModal} />}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  padding: 40px 40px 24px;
`;
const Content = styled.main`
  padding: 0 40px;
  height: 608px;
  width: 100%;
`;
const NoVideo = styled.p`
  margin: 280px auto;
  text-align: center;
  color: #80848a;
  font-size: 14px;
`;
const BtnContainer = styled(FlexWrap)`
  gap: 20px;
  justify-content: flex-end;
  margin-right: 40px;
`;
const BtnIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const UploadInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 50px;
  opacity: 0;
  cursor: pointer;
`;
const Button = styled.button<{ colored?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  ${(props) =>
    props.colored
      ? css`
          background-color: #246cf6;
          color: #ffffff;
        `
      : css`
          background-color: #e5e6e8;
          color: #3a3b3e;
        `}
  font-size: 14px;
  line-height: 150%;
  padding: 15px 0;
  width: 160px;
  font-weight: 600;
  border-radius: 6px;
  :hover {
    opacity: 0.6;
  }
`;
const GuideText = styled.p`
  font-size: 14px;
  color: #5f6165;
`;
const TextBtn = styled.button`
  font-size: 14px;
  color: #80848a;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Table = styled.table`
  margin: 16px 0 40px;
  width: 100%;
`;
const Icon = styled.img`
  width: 16px;
  height: 16px;
`;
export default KioskSetting;
