import { combineReducers } from 'redux';
import refund from './refund';
import validate from './validate';
import user from './user';
import notice from './notice';
import modal from './modal';
import loading from '../loading.store';
import tabletId from './tablet';

const rootReducer = combineReducers({
  refund,
  validate,
  user,
  notice,
  modal,
  loading,
  tabletId,
});
export default rootReducer;
