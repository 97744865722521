import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { IHomeCalendarResponae } from '@/types/home';
import MedicalRefundInfo from './MedicalRefundInfo';
import DateSelector from '../common/DateSelector';
import SupplyFilter from '../common/SupplyFilter';
import FlexWrap from '../common/FlexWrap';
import HospitalsFilter from '../common/HospitalsFilter';
import { useAppSelector } from '@/hooks/useReduxHooks';
import QuestionIcon from '@/assets/Home/Question.png';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

function HomeHeader({ medicalInfo }: IProps) {
  const { hospitals } = useAppSelector((state) => state.user);
  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>환급 현황</Title>
          {medicalInfo.paymentType === 'ORIGINAL' && (
            <Description>
              <img src={QuestionIcon} alt='자세히 살펴보기' width={20} />
              <Modal>환급금이 지급된 건 기준으로 보여집니다.</Modal>
            </Description>
          )}
        </TitleWrapper>
        <FlexWrap gap='10px'>
          {hospitals.length > 0 && <HospitalsFilter />}
          <SupplyFilter />
          <DateSelector />
        </FlexWrap>
      </Header>
      <MedicalRefundInfo medicalInfo={medicalInfo} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const Description = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  &:hover {
    aside {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible;

      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 70px;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent #000000cc transparent;
      }
    }
  }
`;

const Modal = styled.aside`
  position: absolute;
  top: 40px;
  left: -60px;
  display: block;
  background-color: #000000cc;
  color: #ffffff;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  padding: 24px;
  border-radius: 8px;
  text-wrap: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -10px, 0);
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out,
    visibility 200ms ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

export default HomeHeader;
