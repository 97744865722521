import { Icon, Popover } from '@successmode-ktp/kui';
import IMAGE_REFUND_STATEMENT_1 from '@/assets/refund/img_refund_statement_1.png';
import IMAGE_REFUND_STATEMENT_2 from '@/assets/refund/img_refund_statement_2.png';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function MedicalAutoReceiveDescMailModal({ isOpen, onClose }: Props) {
  const stepList = [
    {
      id: 1,
      title: '병원정보 > 세무자료 메일 자동수신 ON',
      image: IMAGE_REFUND_STATEMENT_1,
      description: (
        <>
          <strong>기능 활성화/비활성화 설정은 언제든 변경</strong>할 수
          있습니다.
        </>
      ),
    },
    {
      id: 2,
      title: '수신받을 메일 주소를 입력',
      image: IMAGE_REFUND_STATEMENT_2,
      description: (
        <>
          <strong style={{ color: '#ED0828' }}>
            5일이 주말 혹은 공휴일일 경우 직전 영업일에 발송됩니다.
          </strong>
        </>
      ),
    },
  ];
  return (
    <Popover
      hasBackdrop
      isOpen={isOpen}
      style={{
        padding: '60px 48px',
        borderRadius: '20px',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
      onClickBackdrop={onClose}
    >
      <Header>
        <Title>고객 차트번호 추가 기능 사용방법</Title>
        <CloseButton onClick={onClose}>
          <Icon.X size={36} fillColor='#cccccc' />
        </CloseButton>
      </Header>
      <StepList>
        {stepList.map((stepItem) => (
          <li key={stepItem.id}>
            <StepTitle>
              {stepItem.id}. {stepItem.title}
            </StepTitle>
            {stepItem.description && (
              <StepDescription>{stepItem.description}</StepDescription>
            )}
            <StepImage src={stepItem.image} alt='' />
          </li>
        ))}
      </StepList>
    </Popover>
  );
}

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
`;

const Title = styled.h4`
  color: #246cf6;
  font-size: 36px;
  font-weight: 700;
`;

const CloseButton = styled.button``;

const StepList = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 80px;
  width: 1048px;
`;

const StepImage = styled.img`
  width: 100%;
  margin-top: 24px;
`;

const StepTitle = styled.h4`
  color: #111;
  font-size: 24px;
  font-weight: 700;
`;

const StepDescription = styled.p`
  margin-top: 8px;
  color: #777777;
  font-size: 16px;

  strong {
    color: #111;
    font-weight: 700;
  }

  span {
    font-weight: 700;
  }
`;

export default MedicalAutoReceiveDescMailModal;
