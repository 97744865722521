import {
  getRefundDepartureDate,
  getRefundDepartureSummary,
} from '@/api/refund';
import Footer from '@/components/common/Footer';
import UpdateBanner from '@/components/common/UpdateBanner';
import WarningExpired from '@/components/common/WarningExpired';
import RefundDepartureContent from '@/components/refundDeparture/RefundDepartureContent';
import RefundTab from '@/components/refundStatement/RefundTab';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import {
  refundDepartureDateResponse,
  refundDepartureSummaryResponse,
} from '@/types/refund';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import styled from 'styled-components';

function RefundDeparture() {
  const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

  return (
    <Container>
      <UpdateBanner />
      {isExpiryWarning && (
        <WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
      )}
      <RefundTab currentTab='departure' />
      <RefundDepartureContent />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

export default RefundDeparture;
