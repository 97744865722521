import { IMedicalResponse, MedicalMailResponse } from '@/types/medical';
import api from '..';

// 의료기관 정보
export const medicalInfo = async () => {
  const res = await api.getAxios<IMedicalResponse, null>(`/user/medical-info`);
  return res.data;
};

// 의료기관 메일 조회
export const medicalMail = async () => {
  const res = await api.getAxios<MedicalMailResponse, null>(
    '/user/medical-info/mail',
  );
  return res.data;
};
