export type MediaType = 'video' | 'image' | 'unknown';

export function getMediaType(mediaSourceUrl: string | null): MediaType {
  if (!mediaSourceUrl) return 'unknown';

  const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv', 'webm'];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];

  const extension = mediaSourceUrl.split('.').pop()?.toLowerCase();

  if (videoExtensions.includes(extension || '')) {
    return 'video';
  } else if (imageExtensions.includes(extension || '')) {
    return 'image';
  } else {
    return 'unknown';
  }
}
