import { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@/hooks/useReduxHooks';
import MedicalTabletSettingModal from './MedicalTabletSetting.modal';
import { Icon } from '@successmode-ktp/kui';

function MedicalTabletSetting() {
  const [isOpenTabletModal, setIsOpenTabletModal] = useState(false);
  const { currentTabletID } = useAppSelector((state) => state.tabletId);

  return (
    <>
      <InputContainer>
        <FormWrapper>
          <LabelWrapper>
            <Label>결제 내역 태블릿 연동</Label>
          </LabelWrapper>
          <Button
            onClick={() => {
              setIsOpenTabletModal(true);
            }}
          >
            <Icon.Link fillColor='#246CF6' />
            <span>태블릿 {currentTabletID}</span>
          </Button>
        </FormWrapper>
        <MedicalTabletSettingModal
          isOpen={isOpenTabletModal}
          onClose={() => setIsOpenTabletModal(false)}
        />
      </InputContainer>
    </>
  );
}

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-right: 24px;
`;

const Label = styled.label`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mono[80]};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  border-radius: 8px;
  border: 1px solid var(--KTP-Grayscale-Gray_10, #ddd);
  background: var(--KTP-Grayscale-Gray_14, #fff);
  height: 48px;
  width: 124px;

  padding: 12px 16px;

  span {
    color: var(--Blue-100, #246cf6);
    text-align: center;

    /* WebBody/16_MD */
    font-family: Pretendard sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 24px */
    letter-spacing: -0.32px;
    margin-top: 3px;
  }
`;

export default MedicalTabletSetting;
