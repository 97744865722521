import styled from 'styled-components';
import ICON_CHECKBOX from '@/assets/common/checkedBlue.png';

type Props = {
  touristName?: string;
  supplyDate?: string;
  privacyAgreedTime?: string;
};

const Terms = ({ touristName, supplyDate, privacyAgreedTime }: Props) => {
  const agreeDate = privacyAgreedTime?.split('T')[0] || supplyDate;

  return (
    <Wrapper>
      <Title>■ 개인정보동의서</Title>
      <Table>
        <thead>
          <tr>
            <TermsTitle>
              [Consent to collect, use and disclose of personal Information]
            </TermsTitle>
          </tr>
        </thead>
        <tr>
          <ListWrapper>
            <Contents>
              <span>
                1. Purpose of collection and use of personal information
              </span>
              <UnOrderList>
                <li>
                  To confirm identification and check departure for V.A.T refund
                  of foreign tourists received cosmetic surgery
                </li>
              </UnOrderList>
            </Contents>
            <Contents>
              <span>2. Categories for collecting personal information</span>
              <UnOrderList>
                <li>
                  Personal Information : Name, Date of Birth, Nationality,
                  Passport No
                </li>
                <li>Unique Identification Information : Passport No</li>
              </UnOrderList>
            </Contents>
            <Contents>
              <span>3. Period of holding and using personal information</span>
              <UnOrderList>
                <li>
                  The taxation period which includes the date when the V.A.T
                  refund or remittance is received
                </li>
              </UnOrderList>
            </Contents>
            <Contents>
              <span>4. Withdrawal of consent and contents of disadvantage</span>
              <UnOrderList>
                <li>
                  You have right to withdraw consents. However, consequence
                  follows(You may only receive V.A.T refund at the refund
                  counter within the airport or port of departure)
                </li>
                <li>
                  Personal information will not be disclosed for any other
                  collected purpose
                </li>
              </UnOrderList>
            </Contents>
          </ListWrapper>
        </tr>
        <tr>
          <TermsWrapper>
            <img src={ICON_CHECKBOX} alt='agree' width={40} />
            <Agree>I will consent to disclosure of personal information</Agree>
          </TermsWrapper>
        </tr>
        <tr>
          <TermsWrapper>
            <img src={ICON_CHECKBOX} alt='agree' width={40} />
            <Agree>
              I will consent to disclosure of unique identification information.
            </Agree>
          </TermsWrapper>
        </tr>
      </Table>
      <Sign>
        <div>{agreeDate}</div>
        <div>Name: {touristName}</div>
      </Sign>
    </Wrapper>
  );
};
export default Terms;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 80px 70px 70px;
`;

const Title = styled.div`
  font-size: 18px;
  display: inline;
  margin-bottom: 12px;
`;

const Table = styled.table`
  border: 1px solid gray;
  tr {
    border: 1px solid gray;
  }
`;

const TermsTitle = styled.strong`
  display: block;
  color: var(--KTP-Grayscale-Gray_02, #111);
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.64px;
  padding: 24px;
`;

const Contents = styled.li`
  color: var(--KTP-Grayscale-Gray_03, #333);
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.48px;
`;

const ListWrapper = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px 40px;
`;

const UnOrderList = styled.ul`
  list-style: disc;
  padding-left: 25px;
`;

const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 40px;
`;

const Agree = styled.p`
  color: var(--KTP-Grayscale-Gray_02, #111);
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.48px;
`;

const Sign = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
  color: var(--KTP-Grayscale-Gray_02, #111);
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.48px;
  margin-top: 24px;
`;
