import { Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
};

function PassportNotVerifiedModal({ onClose }: Props) {
  const NOT_VERIFIED_LIST = [
    {
      key: 1,
      kor: '복수여권소지(이중국적)으로 해당 여권으로 입국하지 않은 경우',
      eng: 'Entry was not made with this passport due to dual nationality (multiple passports)',
    },
    {
      key: 2,
      kor: '공항 외 장소로 입국한 경우 (크루즈 관광을 통한 항구 입국 등)',
      eng: 'Entry occurred at a non-airport location (e.g., seaport via a cruise tour)',
    },
    {
      key: 3,
      kor: '군인 가족 등의 사유로 공항에서 입국 심사가 면제되는 경우',
      eng: 'Immigration inspection at the airport was exempted (e.g., military family members)',
    },
  ];

  return (
    <Modal isOpen={true} hasBackdrop>
      <Header>
        <Icon.WarningFilled fillColor='#FF2D55' size={28} />
        <Title>
          <TitleKor>여권 인증 오류</TitleKor>
          <TitleEng>Passport verification error</TitleEng>
        </Title>
      </Header>

      <Body>
        <RedBox>
          <RedText>
            여권정보 혹은 국적을 올바르게 입력했는지 확인해주세요
          </RedText>
        </RedBox>
        <GrayBox>
          <RedText>이런 경우에도 인증 오류가 생길 수 있어요</RedText>
          <RedText>A verification error may also occur in this case </RedText>
          <List>
            {NOT_VERIFIED_LIST.map(({ key, kor, eng }) => (
              <Item key={key}>
                <KorText>
                  <Order>{key}</Order> {kor}
                </KorText>
                <EngText>{eng}</EngText>
              </Item>
            ))}
          </List>
        </GrayBox>
      </Body>

      <Modal.Footer>
        <Modal.Button onClick={onClose}>확인</Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 12px;
`;

const Title = styled.h4`
  color: #030303;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleKor = styled.strong`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
`;

const TitleEng = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const Body = styled.main`
  width: 652px;
`;

const RedBox = styled.section`
  padding: 12px 0;
  background-color: #ffe7eb;
  text-align: center;
  margin-bottom: 12px;
`;

const GrayBox = styled.section`
  padding: 28px 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  background-color: #f5f6f7;
  border-radius: 4px;
`;

const RedText = styled.p`
  color: #ff2d55;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
`;

const Item = styled.li``;

const KorText = styled.p`
  color: #3a3b3e;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const EngText = styled.p`
  color: #5f6165;
  line-height: 21px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
`;

const Order = styled.span`
  display: inline-block;
  color: #ff2d55;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  font-size: 10px;
  font-weight: 700;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  background-color: #ffe7eb;
`;

export default PassportNotVerifiedModal;
