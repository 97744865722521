import { SupplyHeader } from '@/constants/supply';
import styled from 'styled-components';
import SupplyInput from './SupplyInput';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import { Button, Icon } from '@successmode-ktp/kui';
import ChartNumberModal from '../ChartNumberModal';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { useDispatch } from 'react-redux';
import {
  setIsOpenCustomRefundModal,
  updateRefundInfo,
} from '@/store/modules/refund';
import { DIRECT_PRICE } from '@/constants/common';
import { removeComma } from '@/util/format';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCustomKor?: boolean;
};

function SupplyDetail({ setIsOpen, isCustomKor = false }: Props) {
  const { globalStatus } = useGetGlobalStatus();
  const { isPassportCheck } = useAppSelector((state) => state.validate);
  const methods = useFormContext();

  const [isOpenChartModal, setIsOpenChartModal] = useState(false);

  // const resetRefund = () => {
  // 	const supplyList = methods.getValues('supply') || [];
  // 	supplyList.map((supply: any, index: number) => {
  // 		methods.setValue(`supply.${index}.price`, '');
  // 		methods.setValue(`supply.${index}.productType`, '');
  // 		methods.setValue(`supply.${index}.supplyPrice`, '');
  // 		methods.setValue(`supply.${index}.vatPrice`, '');
  // 	});
  // 	methods.setValue('porteRefund', []);
  // };

  const dispatch = useDispatch();
  const { isGroupRefund, temporaryStorage } = useAppSelector(
    (state) => state.refund,
  );

  const onOpenGroupRefundModal = () => {
    dispatch(updateRefundInfo({ isGroupRefund: false }));
    const supplyList = methods.getValues('supply') || [];
    const updatedSupply = [...supplyList].splice(0, 3);
    updatedSupply.map((supply: any, index: number) => {
      methods.setValue(`supply.${index}.price`, '');
      methods.setValue(`supply.${index}.productType`, '');
      methods.setValue(`supply.${index}.supplyPrice`, '');
      methods.setValue(`supply.${index}.vatPrice`, '');
    });
    methods.setValue('supply', updatedSupply);
    methods.setValue('porteRefund', []);
    dispatch(setIsOpenCustomRefundModal(true));
  };

  useEffect(() => {
    const totalPrice = temporaryStorage.supply.reduce((acc, cur) => {
      return (acc += removeComma(cur.price));
    }, 0);

    dispatch(
      updateRefundInfo({
        isGroupRefund: totalPrice > DIRECT_PRICE,
      }),
    );
  }, [temporaryStorage]);

  return (
    <>
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Title>용역 공급 내역</Title>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent:
                globalStatus?.isChartNumberEnabled && isPassportCheck
                  ? 'space-between'
                  : 'flex-end',
              width: '100%',
            }}
          >
            {globalStatus?.isChartNumberEnabled && isPassportCheck && (
              <Button
                size='sm'
                variant='line'
                style={{
                  display: 'inline-flex',
                  width: 'auto',
                  paddingRight: '16px',
                  paddingLeft: '16px',
                }}
                onClick={() => setIsOpenChartModal(true)}
              >
                {methods.getValues('chartNumber')
                  ? `No.${methods.getValues('chartNumber')}`
                  : '차트번호 추가'}
              </Button>
            )}
            {isGroupRefund && (
              <Button
                size='sm'
                style={{
                  width: '104px',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}
                onClick={onOpenGroupRefundModal}
              >
                <Icon.Reload fillColor='var(--Gray-14)' size={16} />
                <span>금액수정</span>
              </Button>
            )}
          </div>
          {/* <Button
					onClick={() => {
						resetRefund();
						setIsOpen(true);
					}}
				>
					다시 입력
				</Button> */}
        </div>
        <SupplyContainer>
          <Header>
            {SupplyHeader.map((item) => (
              <HeaderTitle
                key={item.id}
                flex={item.flex}
                highlight={item.highlight || false}
              >
                {item.title}
              </HeaderTitle>
            ))}
          </Header>
          <SupplyInput isCustomKor={isCustomKor} />
        </SupplyContainer>
      </Container>
      {globalStatus?.isChartNumberEnabled && isOpenChartModal && (
        <ChartNumberModal onClose={() => setIsOpenChartModal(false)} />
      )}
    </>
  );
}

const Container = styled.div`
  position: relative;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  flex-shrink: 0;
`;

const SupplyContainer = styled.div`
  margin-top: 16px;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.mono[20]};
`;

const HeaderTitle = styled.div<{ flex: number; highlight?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  flex: ${(props) => props.flex};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) =>
    props.highlight ? props.theme.mono[65] : props.theme.mono[35]};
`;

// const Button = styled.button`
//   display: flex;
//   padding: 6px 14px;
//   justify-content: center;
//   align-items: center;
//   border-radius: 4px;
//   font-size: 14px;
//   font-weight: ${(props) => props.theme.fontWeight.medium};
//   color: white;
//   background-color: #246cf6;
//   :hover {
//     opacity: 0.7;
//   }
// `;

export default SupplyDetail;
