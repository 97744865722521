import { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

import FlexWrap from '../common/FlexWrap';
import useRefundCheckDataSubscribe from '@/hooks/useRefundCheckDataSubscribe';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { REFUNDSTATUS } from '@/constants/refund.const';

interface IProps {
  title: string;
  content: string | ReactNode;
  btnText: string;
  btnCallback?: () => void; //버튼 동작: 기본 동작(onClose)+btnCallback 실행
  onClose: () => void;
  btnActive?: boolean;
}
function RefundCheckWaitModal({
  content,
  btnText,
  btnCallback,
  onClose,
  btnActive = true,
}: IProps) {
  const primaryButtonRef = useRef<HTMLButtonElement>(null);
  const { hospitalId } = useAppSelector((state) => state.user);
  const { currentTabletID } = useAppSelector((state) => state.tabletId);

  const { refundInfo } = useRefundCheckDataSubscribe(
    hospitalId.toString(),
    currentTabletID,
  );
  const isSent = refundInfo && refundInfo?.status === REFUNDSTATUS.SENT;

  useEffect(() => {
    if (primaryButtonRef.current) {
      primaryButtonRef.current.focus();
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
      if (e.key === 'Enter') {
        if (btnCallback) {
          btnCallback();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [btnCallback, onClose]);

  useEffect(() => {
    if (isSent && btnCallback) {
      btnCallback();
    }
  }, [isSent]);

  return (
    <Container>
      <Modal>
        <FlexWrap>
          <ModalTitle>결제금액을 확인해주세요.</ModalTitle>
        </FlexWrap>
        <Description>{content}</Description>
        <UserCheck>
          <LoadingSpinner />
          고객 정보 동의 대기 중
        </UserCheck>
        <BtnContainer>
          <Button onClick={onClose} active={true} disabled={false}>
            다시 입력
          </Button>

          <Button
            ref={primaryButtonRef}
            onClick={btnCallback}
            colored
            active={btnActive}
            disabled={!btnActive}
          >
            {btnText}
          </Button>
        </BtnContainer>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Modal = styled(FlexWrap)`
  padding: 32px;
  flex-direction: column;
  align-items: center;
  width: 500px;
  background-color: white;
  border-radius: 16px;
  min-width: 400px;
  position: relative;
`;

const ModalTitle = styled.h3`
  margin-bottom: 6px;
  font-size: 21px;
  line-height: 150%;
  font-weight: 700;
  color: #3a3b3e;
`;

const Description = styled.p`
  font-size: 42px;
  line-height: 150%;
  font-weight: 700;
  color: #246cf6;
  margin-bottom: 24px;
`;

const UserCheck = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;

  color: var(--Mono-65, #5f6165);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`;

const Button = styled.button<{ colored?: boolean; active: boolean }>`
  height: 52px;
  flex: 1;
  background-color: ${(props) =>
    props.active ? (props.colored ? '#246cf6' : '#E5E6E8') : '#D3E2FD'};
  color: ${(props) =>
    props.active ? (props.colored ? '#fff' : '#3A3B3E') : '#fff'};
  border: 1px solid #246cf6;
  border: 1px solid ${(props) => (props.active ? '#E5E6E8' : '#D3E2FD')};
  border-radius: 8px;
  font-size: 16px;
  line-height: 150%;
  padding: 14px 20px;
  :hover {
    opacity: 0.85;
  }
`;

const LoadingSpinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #a7c4fb;
  border-top: 3px solid ${(props) => props.theme.blue[100]};
  border-radius: 50%;
  animation: spin 700ms linear infinite;
  margin-right: 10px;
  margin-bottom: 2px;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default RefundCheckWaitModal;
