import { updateRefundCheckState } from '@/api/tablet';
import { useMutation } from 'react-query';

const useUpdateRefundCheckState = (userId: string, tabletId: string) => {
  return useMutation({
    mutationFn: ({
      refundId,
      newState,
    }: {
      refundId: string;
      newState: string;
    }) => updateRefundCheckState(userId, tabletId, refundId, newState),
  });
};

export default useUpdateRefundCheckState;
