import { AxiosRequestConfig } from 'axios';

export const SERVER_PROD = 'https://elb-medical.ktaxpay.com';
export const SERVER_TEST = 'https://elb-medicaltest2.ktaxpay.com';
// export const SERVER_TEST = 'http://192.168.1.198:11001';

export const KIOSK_PROD = 'https://kiosk.ktaxpay.com';
export const KIOSK_SERVER_TEST = 'https://kiosktest.ktaxpay.com';

const currentUrl: string = window.location.href;

const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production' && !currentUrl.includes('test');
};

const getServerUrl = (prodUrl: string, testUrl: string): string => {
  return isProduction() ? prodUrl : testUrl;
};

const MEDICAL_SERVER: string = getServerUrl(SERVER_PROD, SERVER_TEST);
const KIOSK_SERVER: string = getServerUrl(KIOSK_PROD, KIOSK_SERVER_TEST);

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: MEDICAL_SERVER,
  responseType: 'json',
  withCredentials: false,
};

export const axiosKioskRequestConfiguration: AxiosRequestConfig = {
  baseURL: KIOSK_SERVER,
  responseType: 'json',
  withCredentials: false,
};
