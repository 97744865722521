import { Icon, Pagination, Popover } from '@successmode-ktp/kui';
import ICON_ALERT from '@/assets/common/icon_alert.png';
import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';
import { getBannerDetail, readBanner } from '@/api/user';
import { bannerDetailResponse } from '@/types/user';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  showBannerId?: number | null;
};

function UpdateBannerModal({ isOpen, onClose, showBannerId }: Props) {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [bannerId, setBannerId] = useState<number | null>(null);

  const { data } = useQuery<bannerDetailResponse>(
    ['bannerDetail', bannerId, page, totalPage, isFirstPage, isLastPage],
    () =>
      getBannerDetail({
        bannerId,
        size: 3,
        page: page - 1,
      }),
    {
      onSuccess: (data) => {
        setTotalPage(data.bannerInfos.totalPages);
        setIsFirstPage(data.bannerInfos.first);
        setIsLastPage(data.bannerInfos.last);
      },
      keepPreviousData: true,
      enabled: !!bannerId,
    },
  );

  const queryClient = useQueryClient();

  const onCloseModal = async () => {
    onClose();
    queryClient.invalidateQueries('getGlobalStatus');
  };

  useEffect(() => {
    if (showBannerId) {
      setBannerId(showBannerId);
    }
  }, [showBannerId]);

  return (
    <Popover
      isOpen={isOpen && !!data}
      order={4}
      style={{
        padding: '40px',
        borderRadius: '20px',
        height: '95vh',
        backgroundColor: 'var(--Gray-14)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      hasBackdrop
    >
      <Header>
        <Title>
          <img src={ICON_ALERT} alt='' width={24} />
          {data?.title}
        </Title>
        <CloseButton onClick={onCloseModal}>
          <Icon.X size={24} fillColor='#CCCCCC' />
        </CloseButton>
      </Header>
      {data?.content && (
        <ContentContainer dangerouslySetInnerHTML={{ __html: data.content }} />
      )}
      <NoticeWrapper>
        <NoticeList>
          <NoticeItem isHeader>
            <NoticeIndex>
              <IndexLabel>번호</IndexLabel>
            </NoticeIndex>
            <NoticeContent>제목</NoticeContent>
            <NoticeDate>등록일</NoticeDate>
          </NoticeItem>
          {data?.bannerInfos?.content?.map(
            ({ bannerId: itemId, title, createdDate, isNew }, index) => (
              <NoticeItem
                key={itemId}
                onClick={() => setBannerId(itemId)}
                isActive={bannerId === itemId}
              >
                <NoticeIndex>
                  {isNew ? (
                    <NewLabel>NEW</NewLabel>
                  ) : (
                    <IndexLabel>{index + 1 + (page - 1) * 3}</IndexLabel>
                  )}
                </NoticeIndex>
                <NoticeContent>{title}</NoticeContent>
                <NoticeDate>{createdDate}</NoticeDate>
              </NoticeItem>
            ),
          )}
        </NoticeList>
        {totalPage > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            totalPage={totalPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
          />
        )}
      </NoticeWrapper>
    </Popover>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  width: 90vw;
  max-width: 1024px;
  border-bottom: 1px solid #eeeeee;
`;

const Title = styled.strong`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: var(--Blue-100);
  font-size: 18px;
  font-weight: 700;
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
`;

const NoticeWrapper = styled.div``;

const NoticeList = styled.div`
  border-top: 1px solid #e5e6e8;
  margin-bottom: 16px;
`;

const NoticeIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
`;

const NoticeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  width: calc(100% - 310px);
  height: 100%;
`;

const NoticeDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 100%;
`;

const NewLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  background-color: #246cf6;
  padding: 0 6px;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

const IndexLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a3b3e;
  font-size: 14px;
  font-weight: 500;
`;

const NoticeItem = styled.button<{ isHeader?: boolean; isActive?: boolean }>`
  width: 100%;
  display: flex;
  color: #3a3b3e;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #e5e6e8;
  height: 44px;

  ${({ isHeader = false }) =>
    isHeader &&
    css`
      color: #777;
      font-size: 14px;
      font-weight: 500;
      background-color: #fafafa;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: #246cf6;
      font-weight: 600;
      background-color: #f4f8ff;

      ${NewLabel} {
        background-color: #246cf6;
        color: #fff
        font-weight: 600;
      }
    `}
`;

const ContentContainer = styled.div`
  * {
    all: revert;

    .ql-size-10 {
      font-size: 10px !important;
    }
    .ql-size-12 {
      font-size: 12px !important;
    }
    .ql-size-14 {
      font-size: 14px !important;
    }
    .ql-size-16 {
      font-size: 16px !important;
    }
    .ql-size-18 {
      font-size: 18px !important;
    }
    .ql-size-20 {
      font-size: 20px !important;
    }
    .ql-size-24 {
      font-size: 24px !important;
    }
    .ql-size-32 {
      font-size: 32px !important;
    }
    .ql-size-48 {
      font-size: 48px !important;
    }
    .ql-size-64 {
      font-size: 64px !important;
    }
  }

  width: 100%;
  max-width: 1024px;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 8px;
  background-color: #fff;
  padding: 8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 12px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  img {
    width: 100%;
    max-width: 1024px;
  }
`;

export default UpdateBannerModal;
