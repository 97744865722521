import styled from 'styled-components';
import Footer from '@/components/common/Footer';
import RefundDetailList from '@/components/refundDetail/RefundDetailList';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import UpdateBanner from '@/components/common/UpdateBanner';

function RefundDetail() {
  const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

  return (
    <Wrapper>
      <UpdateBanner />
      {isExpiryWarning && (
        <WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
      )}
      <Container>
        <RefundDetailList />
      </Container>
      <Footer />
    </Wrapper>
  );
}

export default RefundDetail;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 40px 85px 40px;
`;
