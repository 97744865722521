import { useAppSelector } from '@/hooks/useReduxHooks';
import { useTabletList } from '@/hooks/useTabletList';
import { setTabletId } from '@/store/modules/tablet';
import { Button, Icon, Input, Modal } from '@successmode-ktp/kui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  moveRefundCheck?: (tabletID: string) => void;
};

function MedicalTabletSettingModal({
  isOpen,
  onClose,
  moveRefundCheck,
}: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { currentTabletID } = useAppSelector((state) => state.tabletId);
  const { hospitalId } = useAppSelector((state) => state.user);
  const { data: tablets } = useTabletList(hospitalId.toString());
  const [tempTabletID, setTempTabletID] = useState(currentTabletID);

  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(setTabletId(tempTabletID));
    if (moveRefundCheck) {
      moveRefundCheck(tempTabletID);
    }
    onClose();
  };
  const handleCurrentTabletID = (tabletID: string) => {
    setTempTabletID(tabletID);
  };

  return (
    <Modal isOpen={isOpen} hasBackdrop>
      <Modal.Header>
        <Title>결제 내역 태블릿 연동</Title>
      </Modal.Header>
      <Content>
        <Discrption>연동할 전자 서명 태블릿 번호를 선택해주세요.</Discrption>
        <TabletNumberSetting>
          <SectionTitle>
            연결된 태블릿
            <Wrapper>
              {dropdownOpen ? (
                ''
              ) : (
                <CurrentTabletNumber>{tempTabletID}</CurrentTabletNumber>
              )}
              <DropdownButton
                onClick={() => {
                  setDropdownOpen((prev) => !prev);
                }}
              >
                <Icon.ChevronDown />
              </DropdownButton>
            </Wrapper>
          </SectionTitle>
          {dropdownOpen ? (
            <SectionBody>
              {tablets.map((id: string) => (
                <TabletNumberButton
                  key={id}
                  onClick={() => {
                    handleCurrentTabletID(id);
                  }}
                  $isCurrent={id === tempTabletID}
                >
                  {id}
                </TabletNumberButton>
              ))}
            </SectionBody>
          ) : (
            ''
          )}
        </TabletNumberSetting>
      </Content>
      <Modal.Footer>
        <Modal.Button variant='cancel' onClick={onClose} />
        <Modal.Button children={'연동하기'} onClick={handleSave} />
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #030303;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 18px;
`;

const Content = styled.main`
  width: 436px;
  box-sizing: border-box;
`;
const Discrption = styled.p`
  color: var(--Mono-65, #5f6165);
  text-align: center;

  /* WebBody/16_MD */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
`;
const TabletNumberSetting = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 0;
  margin: 16px 37px 32px;
  border-radius: 12px;
  background: #fafafa;
  height: fit-content;
`;

const SectionTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 38px;
  color: var(--Mono-80, #3a3b3e);
  /* WebHead/21_BD */
  font-family: Pretendard;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 31.5px */
  letter-spacing: -0.42px;
`;

const Wrapper = styled.div`
  display: flex;
`;
const CurrentTabletNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93px;
  flex-shrink: 0;
  color: var(--Mono-80, #3a3b3e);
  text-align: center;

  /* WebHead/21_BD */
  font-family: Pretendard;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 31.5px */
  letter-spacing: -0.42px;
`;
const SectionBody = styled.div`
  padding-top: 23px;
`;
const DropdownButton = styled.button``;

const TabletNumberButton = styled.button<{ $isCurrent: boolean }>`
  width: 100%;
  height: 46px;
  flex-shrink: 0;

  color: var(--Mono-80, #3a3b3e);
  text-align: center;
  /* WebHead/21_BD */
  font-family: Pretendard;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 31.5px */
  letter-spacing: -0.42px;

  background: ${({ $isCurrent }) => ($isCurrent ? '#E9F0FE' : '#FAFAFA')};
`;

export default MedicalTabletSettingModal;
