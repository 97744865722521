// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDAbfs7lGCUQVp0C1wDa_z2QXwTLokCu_U',
  authDomain: 'medical-tablet.firebaseapp.com',
  projectId: 'medical-tablet',
  storageBucket: 'medical-tablet.firebasestorage.app',
  messagingSenderId: '19633397535',
  appId: '1:19633397535:web:9b5f0d0632a60b10db1a2a',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
