import { medicalMail } from '@/api/medical';
import { MedicalInfoReportMail } from '@/types/medical';
import { Button, Icon, Input, Modal } from '@successmode-ktp/kui';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (mails: string[]) => void;
  data: MedicalInfoReportMail | undefined;
};

function MedicalAutoReceiveMailModal({ isOpen, onClose, onSave, data }: Props) {
  const MAX_EMAILS = 5;
  const [emails, setEmails] = useState<{ email: string; error: string }[]>([
    { email: '', error: '' },
  ]);

  const { data: medicalMailData } = useQuery(['medicalMail'], medicalMail, {
    enabled: data?.mails === null || data?.mails.length === 0,
  });

  const handleChangeEmail = ({
    email,
    index,
  }: {
    email: string;
    index: number;
  }) => {
    const newEmails = emails.map((prevEmail, prevIndex) =>
      prevIndex === index ? { email, error: '' } : prevEmail,
    );
    setEmails(newEmails);
  };

  const handleAddEmailInput = () => {
    setEmails((prev) => [...prev, { email: '', error: '' }]);
  };

  const handleRemoveEmail = ({ index }: { index: number }) => {
    const newEmails = emails.filter(
      (prevEmail, prevIndex) => prevIndex !== index,
    );
    setEmails(newEmails);
  };

  const handleSave = () => {
    const isValidEmails = checkEmails();

    if (isValidEmails) {
      const mails = emails.map((email) => email.email);
      onSave(mails);
    }
  };

  const checkEmails = () => {
    let isValidCount = 0;

    const newEmails = emails.map(({ email }) => {
      if (isNullEmail({ email })) {
        return { email, error: '이메일을 입력해주세요.' };
      }

      if (!isFormattingEmail({ email })) {
        return { email, error: '올바른 이메일 형식을 입력해주세요.' };
      }

      isValidCount += 1;
      return { email, error: '' };
    });

    setEmails(newEmails);
    return isValidCount === emails.length;
  };

  const isNullEmail = ({ email }: { email: string }) => {
    return email.length === 0;
  };

  const isFormattingEmail = ({ email }: { email: string }) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  useEffect(() => {
    if (data?.mails.length) {
      const mailsState = data.mails.map((mail) => {
        return { email: mail, error: '' };
      });
      setEmails(mailsState);
      return;
    }

    if (medicalMailData?.mail) {
      setEmails([
        {
          email: medicalMailData.mail,
          error: '',
        },
      ]);
    }
  }, [data, medicalMailData]);

  return (
    <Modal isOpen={isOpen} hasBackdrop>
      <Modal.Header>
        <Title>
          <Icon.Mail size={32} fillColor='#111111' />
          세무자료 메일 수신
        </Title>
      </Modal.Header>
      <Content>
        <AddMail>
          <p>세무자료를 수신받을 이메일 주소를 확인해주세요.</p>
          <Button
            onClick={handleAddEmailInput}
            style={{ width: '84px' }}
            size='sm'
            variant='line'
            disabled={MAX_EMAILS === emails.length}
          >
            메일 추가
          </Button>
        </AddMail>
        <MailList>
          {emails.map(({ email, error }, index) => (
            <MailItem key={index}>
              <InputWrapper>
                <Input
                  value={email}
                  onChange={(e) =>
                    handleChangeEmail({ email: e.target.value, index })
                  }
                  wrapperStyle={{ width: '100%' }}
                  error={error}
                  hasClearButton={false}
                />
              </InputWrapper>
              {index > 0 && (
                <RemoveButton
                  onClick={() => handleRemoveEmail({ index })}
                  key={index}
                >
                  <Icon.Minus size={24} fillColor='#FF2E2E' />
                </RemoveButton>
              )}
            </MailItem>
          ))}
        </MailList>
        <SendInfo>
          <SendDate>
            <span>발송일</span>
            <span>매달 5일</span>
          </SendDate>
          <SendDesc>
            5일이 주말 혹은 공휴일일 경우 직전 영업일에 발송됩니다.
          </SendDesc>
        </SendInfo>
      </Content>
      <Modal.Footer>
        <Modal.Button variant='cancel' onClick={onClose} />
        <Modal.Button onClick={handleSave} />
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #030303;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 18px;
`;

const Content = styled.main`
  width: 436px;
`;

const AddMail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const MailList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`;

const MailItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ffdada;
  border-radius: 100px;
  padding: 0;
`;

const SendInfo = styled.div``;

const SendDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #f4f4f4;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #777;
  margin-bottom: 8px;
`;

const SendDesc = styled.p`
  color: #999;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default MedicalAutoReceiveMailModal;
