import styled from 'styled-components';

import CloseIcon from '@/assets/common/cancle.png';
import FlexWrap from '@/components/common/FlexWrap';
import { useEffect, useState } from 'react';
import { getMediaType, MediaType } from '@/util/kiosk.util';

type Props = {
  s3Url: string;
  onClose: () => void;
  fileName: string;
};
function PreviewModal({ s3Url, onClose, fileName }: Props) {
  const [mediaType, setMediaType] = useState<MediaType>('unknown');

  useEffect(() => {
    if (s3Url) {
      const type = getMediaType(s3Url);
      setMediaType(type);
    }
  }, [s3Url]);

  return (
    <BackDrop>
      <Container>
        <FlexWrap justifyContent='space-between'>
          <Title>동영상 미리보기</Title>
          <CloseBtn onClick={onClose}>
            <CloseImg src={CloseIcon} />
          </CloseBtn>
        </FlexWrap>
        <FileName>{fileName}</FileName>
        {mediaType === 'video' ? (
          <Video
            preload='metadata'
            autoPlay
            muted
            playsInline
            controls={false}
            loop
          >
            <source src={s3Url} type='video/mp4' />
            Your browser does not support the video tag.
          </Video>
        ) : (
          <Img src={s3Url} alt='kiosk_promotion_img' />
        )}
      </Container>
    </BackDrop>
  );
}

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.5);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;
const Title = styled.h3`
  font-size: 21px;
  color: #030303;
  font-weight: 700;
`;
const CloseBtn = styled.button``;
const CloseImg = styled.img`
  width: 16px;
  height: 16px;
`;
const FileName = styled.p`
  font-size: 12px;
  margin: 12px 0 8px;
  color: #5f6165;
`;
const Video = styled.video`
  width: 390px;
  height: 693px;
  background-color: gray;
  object-fit: cover;
`;
const Img = styled.img`
  width: 390px;
  height: 693px;
  background-color: gray;
  object-fit: cover;
`;
export default PreviewModal;
