import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import {
  postRefundValidation,
  refundRegister,
  refundRegisterGroup,
  temporaryStorageSave,
} from '@/api/refund';
import {
  IRefundPayload,
  IRefundResponse,
  ISubmitData,
  StoragePayload,
} from '@/types/refund';
import AmountDetail from './AmountDetail';
import SupplyDetail from './SupplyDetail';
import TouristInput from './TouristInput';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { refundIndexState } from '@/store/modules/user';
import {
  reset,
  setIsOpenCustomRefundModal,
  setPrivacyAgreementDate,
} from '@/store/modules/refund';
import LeafletModal from '@/components/refund/LeafletModal';
import getCurrentTime, { getNumberFullTime } from '@/util/getCurrentTime';
import {
  attachedHyphensDate,
  convertToKoreanUnit,
  removeComma,
} from '@/util/format';
import { updateModal } from '@/store/modules/modal';
import confirmIcon from '@/assets/common/confirm.png';
import { updateLoading } from '@/store/loading.store';
import PorteRefundModal from './porte/PorteRefund.modal';
import { DIRECT_PRICE, isCustom } from '@/constants/common';
import useScanLog from '@/hooks/useScanLog';
import { debounce } from 'lodash';
import ChartNumberModal from './ChartNumberModal';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import { Icon, Modal } from '@successmode-ktp/kui';
import UpdateBanner from '../common/UpdateBanner';
import useRefundCheckDataSubscribe from '@/hooks/useRefundCheckDataSubscribe';
import useUpdateRefundCheckState from '@/hooks/useUpdateRefundCheckState';
import useDeleteRefundCheck from '@/hooks/useDeleteRefundCheck';
import { REFUNDSTATUS } from '@/constants/refund.const';
import useTabletLog from '@/hooks/useTabletLog';

const orderInfoList = {
  productType: '',
  price: '',
  supplyPrice: '',
  vatPrice: '',
  isCheck: false,
};

interface IProps {
  setIsRefund: Dispatch<SetStateAction<boolean>>;
  isExpired: boolean;
}

interface ISupplyItem {
  price: string;
  productType: string | number;
  supplyPrice: string;
  vatPrice: string;
  isCheck?: boolean;
}

function RefundContent({ setIsRefund, isExpired }: IProps) {
  const { globalStatus, isTabletUser } = useGetGlobalStatus();
  const [isPayment, setIsPayment] = useState(true);
  const [leafletModalInfo, setLeafletModalInfo] = useState({
    active: false,
    isOver600: false,
    isKioskError: false,
  });
  const [isOpenChartModal, setIsOpenChartModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    isSubmit: false,
    message: '',
  });
  const [isFetchedStorageData, setIsFetchedStorageData] = useState(false);
  const { touristIndex, isAutoPayment, isKioskStore, hospitalId } =
    useAppSelector((state) => state.user);
  const {
    isDirectPayment,
    isScanned,
    temporaryStorage,
    isOpenCustomRefundModal,
    privacyAgreementDate,
  } = useAppSelector((state) => state.refund);
  const { isPassportCheck } = useAppSelector((state) => state.validate);
  const dispatch = useAppDispatch();
  // 커스텀 한국인
  const [isCustomKor, setisCustomKor] = useState(false);
  const [isCustomLeaflet, setisCustomLeaflet] = useState(false);

  // 의료 태블릿
  const privacyAgreementDateRef = useRef('');
  const { currentTabletID } = useAppSelector((state) => state.tabletId);
  const { refundInfo } = useRefundCheckDataSubscribe(
    hospitalId.toString(),
    currentTabletID,
  );
  const refundInfoIdRef = useRef(refundInfo?.id);

  const { mutateAsync: updateRefundCheckStateMutate } =
    useUpdateRefundCheckState(hospitalId.toString(), currentTabletID);
  const { mutateAsync: deleteRefundCheckMutate } = useDeleteRefundCheck(
    hospitalId.toString(),
    currentTabletID,
  );
  const { mutate: postLogNCrashTablet } = useTabletLog();

  // 환급하기 할때 서버로 보내는 body 값
  const methods = useForm({
    defaultValues: {
      touristName: '',
      departureDate: '',
      paymentCard: '0',
      paymentCash: '0',
      supply: [orderInfoList, orderInfoList, orderInfoList],
      supplyDate: attachedHyphensDate(getNumberFullTime(new Date())),
      name: '',
      nationality: '',
      passportNumber: '',
      saleDate: getCurrentTime(),
      birthYear: '',
      birthMonth: { label: '', value: '' },
      birthDay: '',
      gender: 'F',
    },
  });
  const { handleSubmit, setValue, setFocus, getValues } = methods;

  const refundCheckDone = async () => {
    if (refundInfo?.id) {
      await updateRefundCheckStateMutate({
        refundId: refundInfo.id,
        newState: REFUNDSTATUS.COMPLETED,
      });
      setTimeout(() => {
        deleteRefundCheckMutate({
          refundId: refundInfo.id,
        });
      }, 5000);
    }
  };

  // TODO - 임시저장 환급 에러 참고
  // 임시저장 값 불러오기
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      setValue('supplyDate', temporaryStorage.supplyDate);
      setValue('touristName', temporaryStorage.touristName);
      setValue('passportNumber', temporaryStorage.passportNumber);
      setValue('nationality', temporaryStorage.nationality);
      setValue('paymentCash', temporaryStorage.paymentCash);
      setIsFetchedStorageData(true);
    }
  }, [temporaryStorage]);

  const onCloseModal = (type?: string) => {
    dispatch(updateModal({ isActive: false, data: null, type: type || null }));
  };

  const deleteRefundCheck = async () => {
    if (refundInfoIdRef.current) {
      await deleteRefundCheckMutate({
        refundId: refundInfoIdRef.current,
      });
    }
  };

  const onCloseModalWithDeleteRefund = async (type?: string) => {
    dispatch(updateModal({ isActive: false, data: null, type: type || null }));
    deleteRefundCheck();
  };

  const queryClient = useQueryClient();
  const { mutate: storageMutation } = useMutation<
    number,
    AxiosError,
    StoragePayload
  >((payload) => temporaryStorageSave(payload), {
    retry: false,
    onSuccess: () => {
      dispatch(
        updateModal({
          isActive: true,
          type: 'CUSTOM',
          data: {
            icon: confirmIcon,
            title: '임시 저장 성공',
            content: '입력하신 정보를 임시저장했습니다.',
            btnText: '확인',
            subBtnText: '',
            onClose: onCloseModal,
          },
        }),
      );
      queryClient.invalidateQueries({ queryKey: 'allStorage' });
    },
    onError: (error) => {
      deleteRefundCheck();
      console.log('에러', error);
    },
  });

  const { mutate: refundMutation, isLoading } = useMutation<
    IRefundResponse,
    AxiosError,
    IRefundPayload
  >((payload) => refundRegister(payload), {
    retry: false,
    onSuccess: (data) => {
      dispatch(refundIndexState(data));
      dispatch(reset());
      setIsRefund(true);
      setIsPayment(false);
      dispatch(updateLoading({ isLoading: false }));
    },
    onError: (error) => {
      deleteRefundCheck();
      dispatch(updateLoading({ isLoading: false }));
      //@ts-ignore
      const { code, message } = error.response?.data;
      switch (code) {
        case 'H0006':
          alert(
            '유치기관 만료일을 초과했습니다. 만료일 갱신 후 다시 시도해 주세요.',
          );
          break;
        default:
          if (message) {
            return alert(message);
          }
          return alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      }
    },
  });

  const onSubmit: SubmitHandler<ISubmitData> = (data) => {
    const {
      paymentCard,
      paymentCash,
      supply,
      supplyDate,
      touristName,
      gender,
    } = data;
    console.log('RefundContent', data);

    let totalPrice = 0;
    let totalRefund = 0;
    const newPaymentCard = paymentCard.replace(/(,)/g, '');
    const newPaymentCash = paymentCash.replace(/(,)/g, '');
    const newSupply = supply.filter(
      (item) => item.price && String(item.productType),
    );

    if (newSupply.length === 0) {
      alert('시술 내역을 입력해주세요.');
      return;
    }

    newSupply.forEach((item: ISupplyItem) => {
      item.productType = +item.productType;
      delete item.isCheck;
    });

    data.supply.forEach((item) => {
      item.price = item.price.replace(/(,)/g, '');
      totalPrice += Number(item.price);
    });
    totalRefund = getRefundAmount(totalPrice);

    if (totalPrice < 15000) {
      setErrorMessage((prev) => ({
        ...prev,
        isSubmit: true,
        message: '최소 금액 15,000원 이상 입니다.',
      }));
      return;
    }

    // @ts-ignore
    if (isCustom(hospitalId) && totalPrice > DIRECT_PRICE && !isCustomKor) {
      return onSubmitPorte(data);
    }
    const payload: IRefundPayload = {
      touristName: touristName.toUpperCase(),
      supplyDate,
      totalRefund: String(totalRefund),
      totalPrice: String(totalPrice),
      paymentCard: String(newPaymentCard),
      paymentCash: String(newPaymentCash),
      touristId: String(touristIndex),
      orderInfoList: newSupply,
      paymentType: !isAutoPayment || isDirectPayment ? 'DIRECT' : 'ORIGINAL',
      isScanned,
      tempInformationId: temporaryStorage.tempInformationId,
      gender: gender,
    };

    // TODO - 임시저장 환급 에러 참고
    // 차감결제인 경우, 총액 비교
    if (payload.paymentType === 'ORIGINAL') {
      const payloadTotal =
        Number(payload.paymentCard) +
        Number(payload.paymentCash) +
        Number(payload.totalRefund);
      if (Number(payload.totalPrice) !== payloadTotal) {
        deleteRefundCheck();
        return alert(
          '환급 생성 중 오류가 발생했습니다. 새로고침 후 다시 시도해 주세요.',
        );
      }
    }

    if (isTabletUser) {
      dispatch(
        updateModal({
          isActive: true,
          type: 'REFUND_CHECK_WAIT',
          data: {
            content:
              convertToKoreanUnit(
                isAutoPayment && !isDirectPayment
                  ? totalPrice - getRefundAmount(totalPrice)
                  : totalPrice,
              ) + '원',
            btnText: '확인 완료',
            onClose: onCloseModalWithDeleteRefund,
            btnCallback: () => {
              debounceRefundValidate(
                () => {
                  if (privacyAgreementDateRef.current) {
                    payload.privacyAgreementDate =
                      privacyAgreementDateRef.current;
                    postLogNCrashTablet({
                      body: `태블릿 환급 동의 완료 :: 고객명:${payload.touristName}, 동의 시간:${privacyAgreementDateRef.current}`,
                      traceId: '',
                    });
                  }
                  refundMutation(payload);
                  dispatch(setPrivacyAgreementDate(''));
                  refundCheckDone();
                },
                supplyDate,
                totalPrice,
              );
            },
          },
        }),
      );
    } else {
      dispatch(
        updateModal({
          isActive: true,
          type: 'PRICE_CHECK',
          data: {
            icon: '',
            title: '결제금액을 확인해주세요. ',
            content:
              convertToKoreanUnit(
                isAutoPayment && !isDirectPayment
                  ? totalPrice - getRefundAmount(totalPrice)
                  : totalPrice,
              ) + '원',
            btnText: '확인',
            subBtnText: '다시 입력',
            onClose: onCloseModalWithDeleteRefund,
            btnCallback: () => {
              debounceRefundValidate(
                () => {
                  if (privacyAgreementDateRef.current) {
                    payload.privacyAgreementDate =
                      privacyAgreementDateRef.current;
                  }
                  refundMutation(payload);
                  dispatch(setPrivacyAgreementDate(''));
                  refundCheckDone();
                },
                supplyDate,
                totalPrice,
              );
            },
          },
        }),
      );
    }
  };

  // 커스텀 의원
  // 커스텀 환급하기
  const { mutate: refundPorte } = useMutation<IRefundResponse, AxiosError, any>(
    (payload) => refundRegisterGroup(payload),
    {
      retry: false,
      onSuccess: (data) => {
        dispatch(refundIndexState(data));
        dispatch(reset());
        setIsRefund(true);
        setIsPayment(false);
        dispatch(updateLoading({ isLoading: false }));
      },
      onError: (error) => {
        deleteRefundCheck();
        dispatch(updateLoading({ isLoading: false }));
        //@ts-ignore
        const { code, message } = error.response?.data;
        switch (code) {
          case 'H0006':
            alert(
              '유치기관 만료일을 초과했습니다. 만료일 갱신 후 다시 시도해 주세요.',
            );
            break;
          default:
            if (message) {
              return alert(message);
            }
            return alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      },
    },
  );

  const onSubmitPorte: SubmitHandler<ISubmitData> = (data) => {
    const { supplyDate, touristName } = data;
    // @ts-ignore
    if (isCustom(hospitalId) && data.porteRefund) {
      let totalPrice = 0;
      let totalRefund = 0;
      let realRefund = 0;

      // @ts-ignore
      const refundDetail = data.porteRefund.map((refund, index) => {
        // @ts-ignore
        const isLast = data.porteRefund.length === index + 1;
        const portePrice = refund.price.replace(/(,)/g, '');
        const porteRefund = refund.refund.replace(/(,)/g, '');
        const differenceRefund =
          removeComma(refund.price) -
          (removeComma(refund.paymentCard) +
            removeComma(refund.paymentCash) +
            getRefundAmount(+portePrice));
        totalPrice += Number(portePrice);
        totalRefund += Number(porteRefund);
        realRefund += getRefundAmount(+portePrice);

        return {
          paymentCard:
            isLast && refund.paymentCard !== '0'
              ? removeComma(refund.paymentCard) + differenceRefund
              : removeComma(refund.paymentCard),
          paymentCash:
            isLast && refund.paymentCard === '0'
              ? removeComma(refund.paymentCash) + differenceRefund
              : removeComma(refund.paymentCash),
          price: removeComma(refund.price),
          refund: getRefundAmount(+portePrice),
          orderInfoList: [
            {
              // @ts-ignore
              productType: data.productType,
              price: +portePrice,
            },
          ],
        };
      });

      const portePayload = {
        common: {
          supplyDate,
          totalRefund,
          totalPrice,
          refundDifference: totalRefund - realRefund,
          touristId: String(touristIndex),
          touristName,
          paymentType: 'ORIGINAL',
          isScanned,
          privacyAgreementDate: '',
        },
        details: refundDetail,
      };

      // 커스텀 의원 15000원 이하 처리
      if (isCustom(hospitalId)) {
        const under15000 = portePayload.details.filter(
          ({ price }: { price: number }) => price < 15000,
        );
        if (under15000.length > 0) {
          setErrorMessage((prev) => ({
            ...prev,
            isSubmit: true,
            message: '각 시술 최소 금액 15,000원 이상 입니다.',
          }));
          return;
        }
      }
      if (isTabletUser) {
        dispatch(
          updateModal({
            isActive: true,
            type: 'REFUND_CHECK_WAIT',
            data: {
              icon: '',
              content:
                convertToKoreanUnit(
                  isAutoPayment && !isDirectPayment
                    ? totalPrice - totalRefund
                    : totalPrice - totalRefund,
                ) + '원',
              btnText: '확인 완료',
              onClose: onCloseModalWithDeleteRefund,
              btnCallback: () => {
                debounceRefundValidate(
                  () => {
                    if (privacyAgreementDateRef.current) {
                      portePayload.common.privacyAgreementDate =
                        privacyAgreementDateRef.current;
                      postLogNCrashTablet({
                        body: `태블릿 환급 동의 완료 :: 고객명:${portePayload.common.touristName}, 동의 시간:${privacyAgreementDateRef.current}`,
                        traceId: '',
                      });
                    }
                    refundPorte(portePayload);

                    refundCheckDone();
                  },
                  supplyDate,
                  totalPrice,
                );
              },
            },
          }),
        );
      } else {
        dispatch(
          updateModal({
            isActive: true,
            type: 'PRICE_CHECK',
            data: {
              icon: '',
              title: '결제금액을 확인해주세요. ',
              content:
                convertToKoreanUnit(
                  isAutoPayment && !isDirectPayment
                    ? totalPrice - totalRefund
                    : totalPrice - totalRefund,
                ) + '원',
              btnText: '확인',
              subBtnText: '다시 입력',
              onClose: onCloseModalWithDeleteRefund,
              btnCallback: () => {
                debounceRefundValidate(
                  () => {
                    refundPorte(portePayload);
                  },
                  supplyDate,
                  totalPrice,
                );
              },
            },
          }),
        );
      }
    }
  };

  const debounceRefundValidate = debounce(
    async (refundFn, supplyDate, totalPrice) => {
      const { items } = await postRefundValidation({
        touristId: touristIndex,
        supplyDate,
        totalPrice: totalPrice.toString(),
      });
      if (items.length !== 0) {
        dispatch(
          updateModal({
            isActive: true,
            type: 'SAME_REFUND_CHECK',
            data: {
              supplyDate,
              data: items,
              onClick: () => {
                dispatch(updateLoading({ isLoading: true }));
                refundFn();
              },
              onClose: onCloseModalWithDeleteRefund,
            },
          }),
        );
      } else {
        dispatch(updateLoading({ isLoading: true }));
        refundFn();
        onCloseModalWithDeleteRefund();
      }
    },
    200,
  );

  const onClickStorage = () => {
    const {
      touristName,
      nationality,
      passportNumber,
      supplyDate,
      supply,
      birthYear,
      birthMonth,
      birthDay,
      paymentCard,
      paymentCash,
    } = methods.getValues();

    const payload = {
      hospitalId,
      supplyDate: supplyDate,
      nation: nationality,
      name: touristName.toUpperCase(),
      passportNumber,
      paymentCash,
      paymentCard,
      orderInfoList: supply,
      year: birthYear,
      month: birthMonth.value,
      day: birthDay,
      tempInformationId: temporaryStorage.tempInformationId,
    };
    storageMutation(payload);
  };

  const focusTouristNameWhenClickAnywhere = (e: any) => {
    const { id, tagName } = e.target;
    if (
      id !== 'no_focus' &&
      !['INPUT', 'BUTTON', 'LI', 'IMG', 'UL'].includes(tagName)
    ) {
      setFocus('touristName');
    }
  };

  // 커스텀 의원
  const [isOpen, setIsOpen] = useState(false);
  const [isOver600, setIsOver600] = useState(false);
  const [isEditedRefund, setIsEditedRefund] = useState(false);

  // 입력값 로그 저장
  const { mutate: postLogNCrash } = useScanLog();

  const inputValue = useRef('');

  useEffect(() => {
    const debouncedPostLog = debounce((body: string, traceId: string) => {
      postLogNCrash(
        { body, traceId },
        {
          onSuccess: () => {
            inputValue.current = '';
          },
        },
      );
    }, 300);
    const UUID = new Date().getTime().toString();

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Shift' && e.key !== 'Enter') {
        inputValue.current += e.key;
      }

      if (e.key === 'Enter' && inputValue.current) {
        debouncedPostLog(
          `여권조회 성공 후 스캔 재시도 = ${isPassportCheck}, 병원명 = ${localStorage.getItem(
            'name',
          )}, 입력값 = ${inputValue.current}`,
          UUID,
        );
        inputValue.current = '';
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPassportCheck]);

  useEffect(() => {
    const isOpenModal = isCustom(hospitalId) && isPassportCheck && isOpen;
    dispatch(setIsOpenCustomRefundModal(isOpenModal));
  }, [hospitalId, isPassportCheck, isOpen]);

  const modalRef = useRef<HTMLButtonElement | null>(null);
  const modalEl = modalRef.current;

  useEffect(() => {
    if (modalEl) {
      setTimeout(() => modalEl.focus(), 200);
    }
  }, [modalEl]);

  useEffect(() => {
    if (privacyAgreementDate) {
      privacyAgreementDateRef.current = privacyAgreementDate;
    }
  }, [privacyAgreementDate]);

  useEffect(() => {
    if (refundInfo?.id) {
      refundInfoIdRef.current = refundInfo.id;
    }
  }, [refundInfo]);

  return (
    <FormProvider {...methods}>
      <UpdateBanner />
      <Container onClick={focusTouristNameWhenClickAnywhere}>
        <TouristInput
          setisCustomLeaflet={setisCustomLeaflet}
          setisCustomKor={setisCustomKor}
          setLeafletModalInfo={setLeafletModalInfo}
          setIsRefund={setIsRefund}
          setIsPayment={setIsPayment}
          isExpired={isExpired}
          setIsOpen={setIsOpen}
          setIsOpenChartModal={setIsOpenChartModal}
        />
        <SupplyDetail setIsOpen={setIsOpen} isCustomKor={isCustomKor} />
        <AmountDetail
          isCustomKor={isCustomKor}
          isPayment={isPayment}
          setLeafletModalInfo={setLeafletModalInfo}
          errorMessage={errorMessage}
          onClickSaveBtn={onClickStorage}
          onClickRefundBtn={handleSubmit(onSubmit)}
          isPassportCheck={isPassportCheck}
          isFetchedStorageData={isFetchedStorageData}
          isLoading={isLoading}
          supplyList={getValues('supply')}
        />
      </Container>

      {/* 커스텀 의원이 아닌 경우 */}
      {!isCustom(hospitalId) &&
        (isKioskStore || isAutoPayment) &&
        leafletModalInfo.active && (
          <LeafletModal
            isKioskStore={isKioskStore}
            leafletModalInfo={leafletModalInfo}
            setLeafletModalInfo={setLeafletModalInfo}
          />
        )}

      {/* 고객 차트번호 입력 필요한 경우 */}
      {globalStatus?.isChartNumberEnabled && isOpenChartModal && (
        <ChartNumberModal onClose={() => setIsOpenChartModal(false)} />
      )}

      {/* 커스텀 의원 + 한국인 경우 */}
      {isCustom(hospitalId) && isCustomKor && isCustomLeaflet && (
        <LeafletModal
          isKioskStore={isKioskStore}
          leafletModalInfo={leafletModalInfo}
          setLeafletModalInfo={setLeafletModalInfo}
          setisCustomLeaflet={setisCustomLeaflet}
        />
      )}
      {/* 커스텀 의원인 경우 */}
      {isOpenCustomRefundModal && (
        <PorteRefundModal
          setIsEditedRefund={setIsEditedRefund}
          onClose={(isOver: boolean) => {
            setIsOpen(false);
            setIsOver600(isOver);
            dispatch(setIsOpenCustomRefundModal(false));
          }}
          isOpenChartModal={
            globalStatus?.isChartNumberEnabled && isOpenChartModal
          }
        />
      )}
      {/* 커스텀 의원인 경우 */}
      {isCustom(hospitalId) && isPassportCheck && isOver600 && (
        <Modal
          isOpen={isCustom(hospitalId) && isPassportCheck && isOver600}
          hasBackdrop
        >
          <Modal.Header>
            <Title>
              <Icon.CheckFilled size={28} fillColor='var(--Blue-100)' />
              {`공급내역 ${isEditedRefund ? '수정' : '입력'}완료`}
            </Title>
          </Modal.Header>
          <Message>
            시술 항목 당 결제금액 {DIRECT_PRICE / 10000}만원을 초과할 수
            없습니다.
          </Message>
          <Modal.Footer>
            <ConfirmButton
              onClick={() => {
                setIsOver600(false);
              }}
              ref={modalRef}
            >
              확인
            </ConfirmButton>
          </Modal.Footer>
        </Modal>
      )}
    </FormProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 36px;
  flex: 1;
  padding: 22px 40px 85px 40px;
  @media ${(props) => props.theme.laptop} {
    gap: 24px;
  }
`;

const Message = styled.p`
  margin: 12px 0 0 0;
  font-size: 14px;
  line-height: 21px;
  color: var(--Mono-65);
  width: 400px;
`;

const ConfirmButton = styled.button`
  border-radius: 8px;
  background-color: #246cf6;
  color: #fff;
  width: 100%;
  height: 42px;
`;

const Title = styled.title`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 0;
  font-size: 21px;
  font-weight: 700;
`;

export default RefundContent;
