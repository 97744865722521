import { deleteRefund } from '@/api/tablet';
import { useMutation } from 'react-query';

const useDeleteRefundCheck = (userId: string, tabletId: string) => {
  return useMutation({
    mutationFn: ({ refundId }: { refundId: string }) =>
      deleteRefund(userId, tabletId, refundId),
  });
};

export default useDeleteRefundCheck;
