import styled from 'styled-components';

import Footer from '@/components/common/Footer';
import RefundStatementContent from '@/components/refundStatement/RefundStatementContent';
import RenewalNotification from '@/components/common/RenewalNotification';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import RefundTab from '@/components/refundStatement/RefundTab';
import UpdateBanner from '@/components/common/UpdateBanner';

function RefundStatement() {
  const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

  return (
    <Container>
      <UpdateBanner />
      {isExpiryWarning && (
        <WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
      )}
      <RefundTab currentTab='statement' />
      <RefundStatementContent />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;
export default RefundStatement;
