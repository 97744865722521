import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import RefundContent from '@/components/refund/RefundContent';
import RefundComplete from '@/components/refund/PaymentComplete/RefundComplete';
import Footer from '@/components/common/Footer';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import PriceCheckModal from '@/components/refund/PriceCheckModal';
import CustomModal from '@/components/refund/CustomModal';
import LoadingView from '@/components/common/LoadingView';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import CashbillUpdateModal from '@/components/home/CashbillUpdateModal';
import { passportCheckState } from '@/store/modules/validate';
import SamePriceCheckModal from '@/components/refund/SameRefundCheck.modal';
import RefundCheckWaitModal from '@/components/refund/RefundCheckWaitModal';
import PassportNotVerifiedModal from '@/components/refund/Error/PassportNotVerifiedModal';
import NonRefundableModal from '@/components/refund/Error/NonRefundableModal';

function Refund() {
  const { isExpiryWarning, globalStatus, isExpired } = useGetGlobalStatus();
  const { modal, loading } = useAppSelector((state) => state);
  const [isRefund, setIsRefund] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpenCashbillModal, setIsOpenCashbillModal] = useState(
    globalStatus?.isCashBillModalVisible || false,
  );

  useEffect(() => {
    setIsActive(modal.isActive);
  }, [modal]);

  useEffect(() => {
    setIsOpenCashbillModal(globalStatus?.isCashBillModalVisible || false);
  }, [globalStatus]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isRefund) {
      dispatch(passportCheckState(false));
    }
  }, [isRefund]);

  return (
    <Fragment>
      <Container>
        {isExpiryWarning && (
          <WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
        )}
        {isRefund ? (
          <RefundComplete />
        ) : (
          <RefundContent setIsRefund={setIsRefund} isExpired={isExpired} />
        )}
        <Footer />
      </Container>
      {isActive && modal?.type === 'CUSTOM' && modal?.isActive && (
        <CustomModal {...modal?.data} />
      )}
      {isActive && modal?.type === 'PRICE_CHECK' && modal?.isActive && (
        <PriceCheckModal {...modal?.data} />
      )}
      {isActive && modal?.type === 'REFUND_CHECK_WAIT' && modal?.isActive && (
        <RefundCheckWaitModal {...modal?.data} />
      )}
      {isActive && modal?.type === 'SAME_REFUND_CHECK' && modal?.isActive && (
        <SamePriceCheckModal {...modal?.data} />
      )}
      {isActive &&
        modal?.type === 'PASSPORT_NOT_VERIFIED_CASE' &&
        modal?.isActive && <PassportNotVerifiedModal {...modal?.data} />}
      {isActive && modal?.type === 'NON_REFUNDABLE_CASE' && modal?.isActive && (
        <NonRefundableModal {...modal?.data} />
      )}

      {loading.isLoading && (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      )}
      {globalStatus?.paymentType === 'ORIGINAL' &&
        globalStatus?.isBeforeNovember && (
          <CashbillUpdateModal
            isOpen={
              globalStatus?.paymentType === 'ORIGINAL' && isOpenCashbillModal
            }
            onClose={() => setIsOpenCashbillModal(false)}
          />
        )}
    </Fragment>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Refund;
