import { useEffect, useState } from 'react';
import { RefundCheck } from '../types/refund.type';
import { refundCheckDataSubscribe } from '@/api/tablet';

function useRefundCheckDataSubscribe(userId: string, tabletId: string) {
  const [refundInfo, setRefundInfo] = useState<RefundCheck[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) return;

    setIsLoading(true);

    const unsubscribe = refundCheckDataSubscribe(
      userId,
      tabletId,
      (refundInfo) => {
        setRefundInfo(refundInfo);
        setIsLoading(false);
      },
    );

    return () => {
      unsubscribe();
    };
  }, [userId, tabletId]);

  return { refundInfo: refundInfo[0], isLoading, error };
}

export default useRefundCheckDataSubscribe;
