import MedicalAutoReceiveMailModal from '@/components/medicalInfo/MedicalAutoReceiveMailModal';
import MedicalAutoReceiveDescMailModal from '@/components/medicalInfo/MedicalAutoReceiveDescMailModal';
import { Icon, Modal, Toggle } from '@successmode-ktp/kui';
import { useState } from 'react';
import styled from 'styled-components';
import { EditReportMailRequest } from '@/types/user';
import { useMutation, useQuery } from 'react-query';
import { editReportMail } from '@/api/user';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { medicalInfo } from '@/api/medical';

function MedicalAutoReceiveMail() {
  const { hospitalId } = useAppSelector((state) => state.user);
  const [isActive, setIsActive] = useState(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [isOpenDescModal, setIsOpenDescModal] = useState(false);
  const [isOpenSuccessSave, setIsOpenSuccessSave] = useState(false);
  const { mutate: mutateEditReportMail } = useMutation(editReportMail);

  const { data: medicalData } = useQuery(
    ['medical', isOpenSuccessSave],
    medicalInfo,
    {
      retry: false,
      staleTime: 0,
      cacheTime: 0,
      onSuccess: (res) => {
        setIsActive(res.reportMail.activateReportMail);
      },
      onError: (error) => {
        console.log('error', error);
      },
    },
  );

  const onToggle = () => {
    if (!isActive) {
      setIsOpenEmailModal(true);
    } else {
      onSaveAutoReceiveMail(
        {
          hospitalId,
          activate: false,
          mails: medicalData?.reportMail?.mails || [],
        },
        () => setIsActive(false),
      );
    }
  };

  const onSaveAutoReceiveMail = (
    payload: EditReportMailRequest,
    onSuccess?: () => void,
  ) => {
    mutateEditReportMail(payload, { onSuccess });
  };

  const handleSaveAutoReceiveMail = (mails: string[]) => {
    onSaveAutoReceiveMail(
      {
        hospitalId,
        activate: true,
        mails,
      },
      () => {
        setIsOpenEmailModal(false);
        setIsOpenSuccessSave(true);
        setIsActive(true);
      },
    );
  };

  return (
    <>
      <InputContainer>
        <FormWrapper>
          <LabelWrapper>
            <Label>세무자료 메일 자동수신</Label>
            <QuestionButton onClick={() => setIsOpenDescModal(true)}>
              <Icon.QuestionFilled fillColor='#9FA1A7' size={24} />
            </QuestionButton>
          </LabelWrapper>

          <Toggle
            name='activeAutoReceiveMail'
            isActive={isActive}
            onToggle={onToggle}
          />
        </FormWrapper>
        {isActive && (
          <Button onClick={() => setIsOpenEmailModal(true)}>
            수신 메일 변경
          </Button>
        )}
      </InputContainer>
      <MedicalAutoReceiveDescMailModal
        isOpen={isOpenDescModal}
        onClose={() => setIsOpenDescModal(false)}
      />
      {isOpenEmailModal && (
        <MedicalAutoReceiveMailModal
          isOpen={isOpenEmailModal}
          onClose={() => setIsOpenEmailModal(false)}
          onSave={handleSaveAutoReceiveMail}
          data={medicalData?.reportMail}
        />
      )}
      <Modal.Confirm
        state='success'
        isOpen={isOpenSuccessSave}
        title='메일 정보 수정완료'
        message='수신 메일 정보가 수정되었습니다.'
        onClick={() => {
          setIsOpenSuccessSave(false);
          setIsOpenEmailModal(false);
        }}
        order={1}
        modalButtonStyle={{ width: '120px' }}
        footerStyle={{ justifyContent: 'flex-end' }}
      />
    </>
  );
}

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-right: 24px;
`;

const Label = styled.label`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mono[80]};
`;

const QuestionButton = styled.button`
  width: 24px;
  height: 24px;
`;

const Button = styled.button`
  border-radius: 8px;
  background: #f4f4f4;
  padding: 12px 16px;
`;

export default MedicalAutoReceiveMail;
