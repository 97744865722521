import { getTabletList } from '@/api/tablet';
import { useQuery } from 'react-query';

export function useTabletList(userId: string) {
  return useQuery({
    queryKey: ['tablet_list', userId],
    queryFn: () => getTabletList(userId),
    enabled: !!userId,
    staleTime: 0,
  });
}
